<template>
  <Panel title="Unmapped Source Data" titleIcon="$map">
    <template #actions>
      <router-link
        class="mt-3"
        :to="{ name: 'SourceData', query: { filter: 'Unmapped' } }"
        >View All Data</router-link
      >
    </template>
    <SourceDataTable
      filter="isMapped eq false and ignoreMapping eq false and isRemoved eq false"
      dense
    />
  </Panel>
</template>

<script>
import Panel from '@/components/Panel.vue'
import SourceDataTable from '../components/SourceDataTable.vue'

export default {
  components: {
    Panel,
    SourceDataTable
  }
}
</script>
