import ViewModel from '@/models/ViewModel'
import IProcessInsight from './IProcessInsight'

export default class ProcessInsight extends ViewModel implements IProcessInsight {
  $_statusColor: string
  processId: number
  processRunId: number
  name: string
  count: number
  status: string
  completedOn: string
  startedOn: string
  showOnDashboard: boolean
  updated: number
  inserted: number
  errorCount: number
  constructor(insight: IProcessInsight) {
    super()
    Object.assign(this, insight)
    switch (this.status) {
      case 'Complete':
        this.$_statusColor = 'success'
        break
      case 'Error':
        this.$_statusColor = 'error'
        break
      case 'Failed':
        this.$_statusColor = 'error'
        break
      default:
        this.$_statusColor = 'warning darken-1'
        break
    }
  }
}
