import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarItems } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VToolbar,{attrs:{"color":_vm.color,"flat":"","dense":"","title":_vm.changeSummary}},[_c(VToolbarTitle,[(_vm.titleIcon)?_c(VIcon,{attrs:{"left":""}},[_vm._v(_vm._s(_vm.titleIcon))]):_vm._e(),_vm._v(" "+_vm._s(_vm.title)+" ")],1),_c(VSpacer),_c(VToolbarItems,[_vm._t("actions")],2)],1),(_vm.error)?_c(VAlert,{staticClass:"ma-0",attrs:{"type":"error","tile":"","dense":"","text":""}},[_vm._v(_vm._s(_vm.error))]):_vm._e(),_c(VProgressLinear,{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"indeterminate":"","height":"2"}}),_c(VSkeletonLoader,{directives:[{name:"show",rawName:"v-show",value:(_vm.loading && _vm.skeletonLoader),expression:"loading && skeletonLoader"}],staticClass:"mx-auto",attrs:{"type":_vm.skeletonLoader}}),(_vm.noData && !_vm.loading)?_c('NoData'):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading && !_vm.noData),expression:"!loading && !noData"}]},[_vm._t("default")],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }