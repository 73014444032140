import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemActionText } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Panel',{attrs:{"title":"Source Data","loading":_vm.loading,"skeletonLoader":"list-item@5"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c(VBtn,{attrs:{"icon":"","title":"Refresh"},on:{"click":_vm.getFilterCounts}},[_c(VIcon,[_vm._v("$refresh")])],1)]},proxy:true}])},[_c(VList,[_vm._l((_vm.filters),function(item,index){return [_c(VListItem,{key:item.name,attrs:{"to":{ name: 'SourceData', query: { filter: item.name } }}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(item.name))])],1),_c(VListItemActionText,[_vm._v(_vm._s(_vm._f("number")(item.count)))])],1),(index < _vm.filters.length - 1)?_c(VDivider,{key:index}):_vm._e()]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }