


























import Vue from 'vue'
import Panel from '@/components/Panel.vue'
import Filter from '@/models/Filter'

export default Vue.extend({
  components: {
    Panel
  },
  computed: {
    loading(): boolean {
      return this.$store.getters['Listings/loading']
    },
    filters(): Filter[] {
      return this.$store.getters['Listings/listingFilters']
    }
  },
  methods: {
    getFilters() {
      this.$store.dispatch('Listings/getFilterCounts')
    }
  },
  created() {
    this.getFilters()
  }
})
