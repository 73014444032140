import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemActionText } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Panel',{staticClass:"fill-height",attrs:{"title":"Import","loading":_vm.loading,"skeleton-loader":"list-item@16"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('router-link',{staticClass:"mt-3",attrs:{"to":{ name: 'Imports' }}},[_vm._v("View All")]),_c(VBtn,{attrs:{"icon":"","title":"Refresh"},on:{"click":_vm.getProcessInsights}},[_c(VIcon,[_vm._v("$refresh")])],1)]},proxy:true}])},[_c('div',{staticClass:"list"},_vm._l((_vm.processInsights.value),function(item,index){return _c(VList,{key:item.$_uuid,staticClass:"text-no-wrap"},[_c(VSubheader,{staticClass:"title"},[_vm._v(" "+_vm._s(item.name)+" "),_c(VSpacer),_c(VChip,{attrs:{"color":item.$_statusColor,"x-small":""}},[_vm._v(_vm._s(item.status))])],1),_c(VListItem,[_c(VListItemTitle,[_vm._v("Last Import")]),_c(VListItemActionText,[_vm._v(_vm._s(_vm._f("datetime")(item.completedOn)))])],1),_c(VDivider),_c(VListItem,{attrs:{"to":_vm.getRoute(item, 'Processed')}},[_c(VListItemTitle,[_vm._v("Processed")]),_c(VListItemActionText,[_vm._v(_vm._s(_vm._f("number")(item.count)))])],1),_c(VDivider),_c(VListItem,{attrs:{"to":_vm.getRoute(item, 'New')}},[_c(VListItemTitle,[_vm._v("New")]),_c(VListItemActionText,[_vm._v(_vm._s(_vm._f("number")(item.inserted)))])],1),_c(VDivider),_c(VListItem,{attrs:{"to":_vm.getRoute(item, 'Updated')}},[_c(VListItemTitle,[_vm._v("Updated")]),_c(VListItemActionText,[_vm._v(_vm._s(_vm._f("number")(item.updated)))])],1),_c(VDivider),_c(VListItem,{attrs:{"to":_vm.getRoute(item, 'Errored')}},[_c(VListItemTitle,[_vm._v("Errors")]),_c(VListItemActionText,[_vm._v(_vm._s(_vm._f("number")(item.errorRecords)))])],1),(index < _vm.processInsights.value.length - 1)?_c(VDivider):_vm._e()],1)}),1)])}
var staticRenderFns = []

export { render, staticRenderFns }