




























import EventHighlightMixin from '@/areas/events/mixins/EventHighlightMixin'

export default EventHighlightMixin.extend({
  props: {
    title: String,
    titleIcon: String,
    loading: Boolean,
    skeletonLoader: String,
    error: Object,
    noData: Boolean
  },
  computed: {
    color(): string {
      return this.highlightColor || 'grey lighten-3'
    }
  }
})
